import { toggleClasses } from 'lambda-dom'
import { fromEvent, merge, Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import {
    WebcastEventSource,
    WebcastInteractive,
    WebcastYearSelection,
    YearQualifiedElement,
} from '../../investor.types'
import { dataYear } from '../../investor.lib'

export class YearTabsInteractive implements WebcastInteractive {

    public readonly selections$: Observable<WebcastYearSelection>
    public readonly source = WebcastEventSource.YearTabs

    private readonly baseYear: number = new Date().getFullYear() - 6

    constructor(private readonly elements: YearQualifiedElement[]) {
        const eventSources = elements.map((element) => fromEvent(element, 'click').pipe(
            map((): WebcastYearSelection => ({
                year: dataYear(element),
                source: this.source,
            })),
        ))

        this.selections$ = merge(...eventSources)

        const parentElement = document.querySelector('.webcasts-publications')

        if (parentElement && parentElement instanceof HTMLElement && parentElement.dataset.baseYear) {
            this.baseYear = parseInt(parentElement.dataset.baseYear)
        }
    }

    reflect(selection: WebcastYearSelection) {
        if (parseInt(selection.year) <= this.baseYear) selection.year = 'archive'

        if (selection.year === 'archive') {
            this.elements.forEach((element) => toggleClasses('active')(element, dataYear(element) === 'archive'))

            return
        }

        if (! selection.year.includes(String(this.baseYear))) {
            this.elements.forEach((element) => toggleClasses('active')(element, dataYear(element) === selection.year))

            return
        }

        this.elements.forEach((element) => toggleClasses('active')(element, parseInt(dataYear(element)) <= this.baseYear))
    }
}
