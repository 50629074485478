import { queryOneWithin } from 'lambda-dom'
import { findIndex } from 'ramda'
import { fromEventPattern, Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import Swiper, { Navigation, Pagination } from 'swiper'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import {
    WebcastEventSource,
    WebcastInteractive,
    WebcastYearSelection,
    YearQualifiedElement,
} from '../../investor.types'
import { dataYear } from '../../investor.lib'

export class SwiperInteractive implements WebcastInteractive {

    public readonly selections$: Observable<WebcastYearSelection>
    public readonly source = WebcastEventSource.Carousel

    private readonly swiper: Swiper
    private readonly baseYear: number = new Date().getFullYear() - 6

    constructor(element: HTMLElement) {
        this.swiper = new Swiper(element, {
            modules: [Navigation, Pagination],
            roundLengths: true,
            allowTouchMove: false,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
                renderBullet: (index: number, className: string) => `<span class="${className}"></span>`,
            },
        })

        this.selections$ = fromEventPattern(
            (handler) => this.swiper.on('slideChange', handler),
            (handler) => this.swiper.off('slideChange', handler),
        ).pipe(
            tap(() => this.initializeCurrentSlide()),
            map((): WebcastYearSelection => ({
                year: dataYear(this.getActiveSlide()),
                source: this.source,
            })),
        )

        const parentElement = document.querySelector('.webcasts-publications')

        if (parentElement && parentElement instanceof HTMLElement && parentElement.dataset.baseYear) {
            this.baseYear = parseInt(parentElement.dataset.baseYear)
        }
    }

    public reflect(selection: WebcastYearSelection): void {
        if (selection.source === this.source) {
            return
        }

        let newIndex: number

        if (selection.year === 'archive') {
            // @ts-ignore
            newIndex = findIndex((slide) => parseInt(dataYear(slide)) <= this.baseYear)(this.swiper.slides)
        } else if (! selection.year.includes(String(this.baseYear))) {
            // @ts-ignore
            newIndex = findIndex((slide) => dataYear(slide) === selection.year)(this.swiper.slides)
        } else {
            // @ts-ignore
            newIndex = findIndex((slide) => parseInt(dataYear(slide)) <= this.baseYear)(this.swiper.slides)
        }

        if (newIndex === -1) {
            return // We might not have a slide for the year
        }

        this.swiper.slideTo(newIndex, undefined, false)
        this.initializeCurrentSlide()
    }

    protected getSlideByIndex(index: number): YearQualifiedElement {
        return this.swiper.slides[index] as YearQualifiedElement
    }

    protected getActiveSlide(): YearQualifiedElement {
        return this.getSlideByIndex(this.swiper.realIndex)
    }

    protected initializeCurrentSlide(): void {
        const iframe = queryOneWithin(this.getActiveSlide())('iframe.slide--iframe')
        if (iframe && iframe.src !== iframe.dataset.src) {
            iframe.src = iframe.dataset.src!
        }
    }
}
