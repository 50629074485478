import { queryAll, touchElement } from 'lambda-dom'
import { construct, pluck } from 'ramda'
import { merge, NEVER } from 'rxjs'
import { startWith } from 'rxjs/operators'

import { noop } from '@app-helpers'
import { DropdownInteractive, PublicationsInteractive, SwiperInteractive, YearTabsInteractive } from './interactives'
import { WebcastEventSource, WebcastInteractive, WebcastYearSelection, YearQualifiedElement } from '../investor.types'
import { dataYear } from '../investor.lib'

const noopInteractive = (): WebcastInteractive => ({
    selections$: NEVER,
    source: WebcastEventSource.NEVER,
    reflect: noop,
})

const yearTabs = queryAll<YearQualifiedElement>('.webcasts--year-selector--year[data-year]')
const publications = queryAll<YearQualifiedElement>('.publications--list .publication[data-year]')

if (yearTabs.length > 0 && publications.length > 0) {
    const interactives = [
        new YearTabsInteractive(yearTabs),
        new PublicationsInteractive(publications),
        touchElement('div.webcasts--slider', construct(SwiperInteractive)) || noopInteractive(),
        touchElement('select.publications--year-selector', construct(DropdownInteractive)) || noopInteractive(),
    ]

    merge(...pluck('selections$', interactives)).pipe(
        startWith(<WebcastYearSelection>{
            year: dataYear(yearTabs[0]),
            source: WebcastEventSource.Manual,
        }),
    ).subscribe((selection) => interactives.forEach((interactive) => interactive.reflect(selection)))
}
