import { ChartConfiguration } from 'chart.js'
import Chart from 'chart.js/auto'
import { getMeta } from 'lambda-dom'
import { keys, values } from 'ramda'

import { regularFontFamily } from '@app-config'
import { YearlySharePrice } from '../investor.types'

// ------------------------------------------------------------------------------
//      Sets the default font for chart labels and such
// ------------------------------------------------------------------------------

Chart.defaults.font.family = regularFontFamily

// ------------------------------------------------------------------------------
//      Chart data: keys are x-axis labels, values are plot points
// ------------------------------------------------------------------------------
//
//      The current year is the last close price of shares, if available. If not,
//      it will not be added to the data set for the chart.

const CHART_DATA: { [key: string]: number } = (() => {

    const yearlySharePrices = getMeta<YearlySharePrice[]>('share-prices', JSON.parse) ?? []
    const latestClosePrice = getMeta('share-close-price')

    if (latestClosePrice !== null) {
        yearlySharePrices.push({
            year: 'current',
            price: latestClosePrice,
        })
    }

    return yearlySharePrices.reduce((acc, { year, price }) => ({
        ...acc,
        [year.replace(/^\d{2}/, '\'')]: parseFloat(price),
    }), {})
})()

// ------------------------------------------------------------------------------
//      The composed Chart.js configuration
// ------------------------------------------------------------------------------

export const shareChartConfig: ChartConfiguration<'line'> = {
    type: 'line',
    data: {
        labels: keys(CHART_DATA),
        datasets: [
            {
                data: values(CHART_DATA),
                borderWidth: 2,
                fill: false,
                borderColor: 'white',
                pointBackgroundColor: 'white',
                tension: 0,
            },
        ],
    },
    options: {
        aspectRatio: 1.5,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                border: {
                    display: true,
                    width: 1,
                    color: 'rgba(255, 255, 255, 0.5)',
                },
                grid: {
                    drawTicks: false,
                    lineWidth: 0,
                },
                ticks: {
                    color: 'white',
                    padding: 10,
                },
            },
            y: {
                border: {
                    display: true,
                    width: 1,
                    color: 'rgba(255, 255, 255, 0.5)',
                },
                grid: {
                    drawTicks: false,
                    lineWidth: 0,

                },
                suggestedMin: 0,
                ticks: {
                    maxTicksLimit: 50,
                    stepSize: 10,
                    color: 'white',
                    padding: 10,
                },
            },
        },
    },
}
