import EventDelegation from '@jjwesterkamp/event-delegation'
import Chart from 'chart.js/auto'
import { queryAll, toggleClasses, touchAll, touchElement } from 'lambda-dom'
import { isNil, not } from 'ramda'
import { BehaviorSubject, fromEvent } from 'rxjs'
import { distinctUntilChanged } from 'rxjs/operators'
import Swiper, { Navigation } from 'swiper'

import 'swiper/css'
import 'swiper/css/navigation'

import '@app-bootstrappers/banner.bootstrapper'
import { HeaderTheme, setHeaderDisplay, setHeaderTheme } from '@app-components'
import { hashAutoScroll, haveClassIf, haveClassUnless, showIf } from '@app-helpers'
import { autoSearch, newsSubscriptionSearch, phoneStatementSlider } from '@app-services'
import { shareChartConfig } from './config'
import { handleGeneralMeetingDownloadsAnchor } from './investor.lib'
import { YearQualifiedElement } from './investor.types'
import './webcasts/investor.webcasts'


newsSubscriptionSearch('#news-subscription-form')

// ------------------------------------------------------------------------------
//      Initialize the 10-year graph of annual close prices
// ------------------------------------------------------------------------------

touchElement('canvas#quote-chart', (canvas) => new Chart(
    canvas.getContext('2d')!,
    shareChartConfig,
))

phoneStatementSlider()
autoSearch()
hashAutoScroll()

// ------------------------------------------------------------------------------
//      Navigation theme handling
// ------------------------------------------------------------------------------
{
    const heroContainer = document.getElementById('who-we-are-hero')!
    const observer = new IntersectionObserver(([element]) => {
        if (element.intersectionRatio >= 0.85) {
            setHeaderDisplay('none')
            setHeaderTheme(HeaderTheme.TRANSPARENT_WHITE)
        } else {
            setHeaderDisplay('brand-logo')
            setHeaderTheme(HeaderTheme.WHITE)
        }
    }, { threshold: [1, 0.85, 0.1, 0] })

    observer.observe(heroContainer)
}

// ------------------------------------------------------------------------------
//      Financial Calendar slider handling
// ------------------------------------------------------------------------------
const swiper = new Swiper('.financial-calendar--slider', {
    modules: [Navigation],
    slidesPerView: 'auto',
    loop: false,
    roundLengths: true,
    navigation: {
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next',
    },
})

// ------------------------------------------------------------------------------
//      Financial Calendar mobile handling
// ------------------------------------------------------------------------------
{
    const showCalendarButton = document.getElementById('toggle-calendar')!
    const eventContainer = document.querySelector('.financial-calendar--expandable') as HTMLElement
    const expandText = showCalendarButton?.getAttribute('data-expand')
    const collapseText = showCalendarButton?.getAttribute('data-collapse')
    const lastNonExpandable = document.querySelector('.financial-calendar--event:last-of-type')
    const buttonTextContainer = showCalendarButton.querySelector('.toggle-calendar--text') as HTMLElement

    if (showCalendarButton) {
        buttonTextContainer.innerText = expandText ?? ''
    }

    fromEvent(showCalendarButton, 'click').subscribe(() => {
        if (eventContainer?.classList.contains('expanded')) {
            eventContainer.classList.remove('expanded')
            buttonTextContainer.innerText = expandText ?? ''
            showCalendarButton.classList.remove('flipped')
            setTimeout(() => {
                lastNonExpandable?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                })

            }, 250)
        } else {
            eventContainer?.classList.add('expanded')
            buttonTextContainer.innerText = collapseText ?? ''
            showCalendarButton.classList.add('flipped')

        }
    })
}

// ------------------------------------------------------------------------------
//      General meeting section handling
// ------------------------------------------------------------------------------
const generalMeetingHandler = (parent: HTMLElement | null | undefined) => {

    if (isNil(parent)) {
        return
    }

    const tabs = queryAll<YearQualifiedElement>('.gm-tab', parent)
    const tabPanels = queryAll<YearQualifiedElement>('.gm-tabpanel', parent)

    const selectYear = (year: string) => {

        for (const tab of tabs) {
            const shouldBeActive = tab.dataset.year === year
            toggleClasses('active')(tab, shouldBeActive)
            tab.setAttribute('aria-selected', shouldBeActive ? 'true' : 'false')
        }

        for (const panel of tabPanels) {
            const shouldBeActive = panel.dataset.year === year
            toggleClasses('active')(panel, shouldBeActive)
            panel.setAttribute('aria-hidden', shouldBeActive ? 'false' : 'true')
        }
    }

    EventDelegation
        .within(parent)
        .events('click')
        .select<YearQualifiedElement>('.gm-tab')
        .listen((e) => selectYear(e.delegator.dataset.year))

    if (tabs[0]) {
        selectYear(tabs[0].dataset.year)
    }
}

generalMeetingHandler(document.querySelector<HTMLElement>('.gm-mobile'))
generalMeetingHandler(document.querySelector<HTMLElement>('.gm-desktop'))

// ------------------------------------------------------------------------------
//      Share info card
// ------------------------------------------------------------------------------
touchAll([
    '#share-info-toggle',
    '#actual-label',
    '#ten-year-label',
    '#actual-container',
    '#ten-year-container',
    '#financial-calendar-toggle',
    '#all-events-cards-label',
    '#financial-calendar-cards-label',
], (
    toggle: HTMLElement,
    actualLabel: HTMLElement,
    tenYearLabel: HTMLElement,
    actualContainer: HTMLElement,
    tenYearContainer: HTMLElement,
    financialCalendarToggle: HTMLElement,
    allEventsLabel: HTMLElement,
    financialCalendarLabel: HTMLElement,
) => {
    const flipped$ = new BehaviorSubject(false)
    const financialCalendar$ = new BehaviorSubject(false)

    const hideNonFinancialCalendarItems = (isCalendar: boolean, showCalendarButton: HTMLElement): void => {
        const financialCalendarItems = document.querySelectorAll<HTMLElement>('[data-financial-calendar=\'0\']')
        financialCalendarItems.forEach((element: HTMLElement) => haveClassIf(isCalendar, element, 'hidden'))
        if (! showCalendarButton.classList.contains('flipped')) {
            showCalendarButton.click()
        }
        swiper.slideTo(0)
    }

    const setFlipped = (flipped: boolean) => flipped$.next(flipped)
    const toggleMode = (): void => setFlipped(not(flipped$.getValue()))
    const setFinancialCalendar = (isCalendar: boolean) => financialCalendar$.next(isCalendar)
    const toggleFinancialCalendar = (): void => setFinancialCalendar(not(financialCalendar$.getValue()))
    const showCalendarButton = document.getElementById('toggle-calendar')!

    toggle.addEventListener('click', toggleMode)
    financialCalendarToggle.addEventListener('click', toggleFinancialCalendar)
    actualLabel.addEventListener('click', () => setFlipped(false))
    tenYearLabel.addEventListener('click', () => setFlipped(true))

    flipped$.pipe(distinctUntilChanged()).subscribe((flipped) => {
        haveClassUnless(flipped, actualLabel, 'active')
        haveClassIf(flipped, toggle, 'active')
        haveClassIf(flipped, tenYearLabel, 'active')
        showIf(flipped, tenYearContainer)
        showIf(! flipped, actualContainer)
    })

    financialCalendar$.pipe(distinctUntilChanged()).subscribe((isCalendar) => {
        haveClassUnless(isCalendar, allEventsLabel, 'active')
        haveClassIf(isCalendar, financialCalendarToggle, 'active')
        haveClassIf(isCalendar, financialCalendarLabel, 'active')
        hideNonFinancialCalendarItems(isCalendar, showCalendarButton)
    })
})

handleGeneralMeetingDownloadsAnchor()
